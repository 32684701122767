function init() {
    $.ncsFileLinks();
    $.fn.ncsExternalLinks();
    $('form')
        .ncsForms();
    $('.no-touch body')
        .ncsTopLink();
    $('.biglink')
        .ncsBigLink();


    // Tooltips & Popovers
    // -------------------------------------------------- //
    $('[data-toggle="tooltip"]')
        .tooltip();
    $('[data-toggle="popover"]')
        .popover();


    // Fancybox
    // -------------------------------------------------- //
    $('.fancybox')
        .fancybox();

    $('.fancybox-media')
        .fancybox({
            openEffect: 'none',
            closeEffect: 'none',
            helpers: {
                media: {}
            }
        });

    $('.modal-link')
        .click(function(evt) {
            evt.preventDefault();
            var url = this.href;

            if ($(window)
                .width() > 767) {
                $.fancybox.open({
                    type: 'iframe',
                    minWidth: 700,
                    maxWidth: 900,
                    href: url + '?view=modal'
                });
            } else {
                window.location = url + '?fullscreen=1'
            }
        });

    $('.modal-form')
        .click(function(evt) {
            evt.preventDefault();
            var url = this.href;

            $.fancybox.open({
                type: 'iframe',
                minWidth: 700,
                maxWidth: 900,
                href: url + '?view=modal',
                afterClose: function() {
                    parent.location.reload(true);
                }
            });
        });

    $('.delete-btn')
        .click(function(evt) {
            evt.preventDefault();

            if (confirm('Are you sure? This action is permanent!')) {
                $('#delete-form')
                    .submit();
            }
        });


    // Datetime Picker
    // -------------------------------------------------- //
    // $('.datetime')
    //     .datetimepicker();

    // $('.date')
    //     .datetimepicker({
    //         format: 'MM/DD/YYYY'
    //     });

    // $('.time')
    //     .datetimepicker({
    //         format: 'h:mm A'
    //     });
}
